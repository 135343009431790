
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'business-logo-frame',
  props: {
    containerClass: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    fill: {
      type: String,
      default: 'none',
    },
    height: {
      default: '25px',
      type: String,
    },
    padding: {
      default: '2px',
      type: String,
    },
    showError: {
      type: Boolean,
      default: true,
    },
    errorDefault: {
      type: String,
      default: 'icon',
    },
    errorText: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: process.env.VUE_APP_TEXT_COLOR,
    },
    backgroundColor: {
      type: String,
      default: process.env.VUE_APP_BACKGROUND_COLOR,
    },
  },
});

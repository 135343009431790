<template>
  <div class="report-header d-flex align-items-center justify-content-center">
    <img
      src="@/views/v2/assets/images/logo/tenant-hub-logo-regular.png"
      alt="Tenant Hub"
    />
  </div>
</template>
<style lang="scss" scoped>
.report-header {
  width: 100%;
  height: 80px;
  background: #fff;
  img {
    height: 45px;
  }
}
</style>

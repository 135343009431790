import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Html2pdf = _resolveComponent("Vue3Html2pdf")!

  return (_openBlock(), _createBlock(_component_Vue3Html2pdf, {
    "show-layout": _ctx.showLayout,
    "float-layout": _ctx.floatLayout,
    "enable-download": _ctx.enableDownload,
    "preview-modal": _ctx.previewModal,
    "paginate-elements-by-height": 1400,
    filename: _ctx.fileName,
    "pdf-quality": 2,
    "manual-pagination": _ctx.manualPagination,
    "pdf-format": _ctx.pdfFormat,
    "pdf-orientation": _ctx.orientation,
    "pdf-content-width": "100%",
    ref: "html2Pdf",
    onHasStartedGeneration: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hasStartedGeneration())),
    onHasGenerated: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hasGenerated($event))),
    onBeforeDownload: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleBeforeDownload($event))),
    onHasDownloaded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleHasDownloaded($event)))
  }, {
    "pdf-content": _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }, 8, ["show-layout", "float-layout", "enable-download", "preview-modal", "filename", "manual-pagination", "pdf-format", "pdf-orientation"]))
}
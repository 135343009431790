
import { defineComponent } from 'vue';
import maskUtil from '@/utils/mask';
import { acronym, capitalize, numberFormat } from '@/utils/text';
import Avatar from '@/components/avatars/Avatar.vue';
import { User } from '@/models/UserModel';

export default defineComponent({
  components: { Avatar },
  props: {
    info: {
      default: () => {
        return {};
      },
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
    tenantInfo: {
      type: Object,
      default: () => {
        return {} as unknown as User;
      }
    }
  },
  computed: {
    rate() {
      return numberFormat(this.averageRating, 1);
    },
    mobileMasking() {
      return maskUtil.mobile.aus;
    },

    fullName() {
      const fname = capitalize((this.tenantInfo as any)?.first_name || '');
      const lname = capitalize((this.tenantInfo as any)?.last_name || '');

      if (fname == '' && lname == '') {
        return '';
      }

      return `${fname} ${lname}`;
    },
    tenantAcronym() {
      const firstName = (this.tenantInfo as any)?.first_name;
      const lastName = (this.tenantInfo as any)?.last_name;

      if (firstName == '' && lastName == '')
        return acronym((this.tenantInfo as any)?.email);

      return acronym(`${firstName} ${lastName}`);
    },
    tenantAvatarSrc() {
      return (this.tenantInfo as any)?.avatar_src;
    },
    tenantRole() {
      return 'Tenant';
    },
  },
});

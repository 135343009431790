import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _ctx.src,
    onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.imageUrlAlt && _ctx.imageUrlAlt(...args)))
  }, null, 40, _hoisted_1))
}

import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        src: {
            default: ''
        },
        altImage: {
            default: '@/assets/images/map.png'
        }
    },
    methods:{
        imageUrlAlt(event) {
            event.target.src = this.altImage
        }
    }
})

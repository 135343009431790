<template>
  <div class="report-footer d-flex align-items-center">
    <div style="margin-right: auto">
      <img
        src="@/views/v2/assets/images/logo/tenant-hub-logo-regular.png"
        alt=""
      />
      <p class="footer-copy">TenantHub Pty Ltd © 2023</p>
    </div>
    <span class="text">
      Tenant Reference Report generated by <strong>Tenant Hub</strong>
    </span>
  </div>
</template>
<style lang="scss" scoped>
.report-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-top: 300px;
  left: 0;
  right: 0;
  padding: 20px;
  background: #fff;

  img {
    height: 38px;
    display: block;
  }

  .copyright {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    color: #737373;
    display: block;
    margin-top: -8px;
    margin-left: 10px;
  }

  .text {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #212121;
  }
}
</style>


import { defineComponent } from 'vue';
import text from '@/utils/text';
import timeUtil from '@/utils/time';

import BusinessLogo from '@/components/logo/BusinessLogo.vue';
import Avatar from '@/components/avatars/Avatar.vue';
import GoogleMapImage from '@/components/image/GoogleMapImage.vue';
import NoReviewSubmitted from '@/views/v2/views/pages/ViewTenantReview/NoReviewSubmitted.vue';
import { money } from '@/utils/currency';

const badgeColor = {
  yes: 'badge-yes',
  no: 'badge-no',
  'n/a': 'badge-not-applicable',
};
export default defineComponent({
  props: {
    info: {
      default: () => {
        return {} as any;
      },
    },
    map: { default: '' },
    type: { default: 'download' }
  },
  components: {
    BusinessLogo,
    Avatar,
    GoogleMapImage,
    NoReviewSubmitted
  },
  computed: {
    hasEmail() {
      return (this.info as any)?.user?.email;
    },
    hasMobileNumber() {
      return (this.info as any)?.user?.mobile_number;
    },
    address() {
      return (this.info as any)?.user?.roles[0].role == 'Admin'
        ? null
        : `${(this.info as any)?.user?.business_address_line}, ${
            (this.info as any)?.user?.city
          }, ${(this.info as any)?.user?.business_state}, ${
            (this.info as any)?.user?.post_code
          }`;
    },
    tenantAddress() {
      return `${(this.info as any)?.address_line}, ${
        (this.info as any)?.city
      }, ${(this.info as any)?.post_code}, ${(this.info as any)?.state}`;
    },
    businessName() {
      return (this.info as any)?.user?.business_name;
    },
    businessLogoSrc() {
      return (this.info as any)?.user?.business_logo_src;
    },
    businessBackgroundColor() {
      return (this.info as any)?.user?.business_color;
    },
    businessTextColor() {
      return (this.info as any)?.user?.business_text_color;
    },
    agentAvatarSrc() {
      return (this.info as any)?.user?.avatar_src;
    },
    agentAcronym() {
      const firstName = (this.info as any)?.user?.first_name;
      const lastName = (this.info as any)?.user?.last_name;

      if (firstName === '' && lastName === '')
        return text.acronym((this.info as any)?.user?.email);

      return text.acronym(`${firstName} ${lastName}`);
    },
    hasLogo() {
      return (
        (this.info as any)?.user?.business_logo &&
        (this.info as any)?.user?.business_logo.trim() != ''
      );
    },
    headerColor() {
      if (!(this.info as any)?.user?.business_color) {
        return;
      }

      return (this.info as any)?.user?.business_color ?? '#4070ea';
    },
    formattedAbn() {
      return this.info?.user?.abn ? text.formatABN(this.info?.user?.abn) : null;
    },
    formattedAcn() {
      return this.info?.user?.acn ? text.formatACN(this.info?.user?.acn) : null;
    },
    notReviewed() {
      return this.info?.is_not_reviewed === 'yes'
    },
    rentPerWeek(){
      return money.format(this.info?.rent_per_week);
    }
  },
  methods: {
    getColor(answer = '' as string) {
      return ((typeof answer !== 'undefined' && answer !== null && answer !== '') || (Array.isArray(answer) && answer.length))
        ? badgeColor[answer.toLowerCase()]
        : '';
    },
    dateFormat(date) {
      return timeUtil.dateFormat(date);
    },
    numberWithCommas(x: any) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatAverageRating(rating) {
      return text.numberFormat(rating);
    },
    renderPhoneNumber(phoneNo) {
      return `tel:${phoneNo.replace(/[^0-9]/g, '')}`;
    },
  },
});

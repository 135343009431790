
import { defineComponent } from 'vue';
import Header from '@/views/v2/views/pages/Report/Header.vue';
import Title from '@/views/v2/views/pages/Report/Title.vue';
import TenantHeader from '@/views/v2/views/pages/Report/TenantHeader.vue';
import ReportContent from '@/views/v2/views/pages/Report/ReportContent.vue';
import Footer from '@/views/v2/views/pages/Report/Footer.vue';
import NoReview from '@/views/v2/views/pages/Report/NoReview.vue';
import { User } from '@/models/UserModel';

export default defineComponent({
  props: {
    fileName: { default: 'Report' },
    orientation: { default: 'portrait' },
    info: {
      default: () => {
        return {};
      },
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
    map: { default: '' },
    type: { default: 'download' },
    tenantInfo: {
      type: Object,
      default: () => {
        return {} as unknown as User;
      }
    }
  },
  components: {
    Header,
    Title,
    TenantHeader,
    ReportContent,
    Footer,
    NoReview
  },
  computed: {
    isEmpty(){
      return !this.info || Object.keys(this.info).length <= 4
    }
  },
  methods: {
    generatePDf() {
      (this.$refs.html2Pdf as any).generatePdf();
    },
  },
});

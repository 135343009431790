
import Vue3Html2pdf from 'vue3-html2pdf';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'pdf-generator',
  emits: ['generatingReport', 'generatedReport', 'beforeDownload'],
  props: {
    pdfFile: {
      type: String,
      default: '',
    },
    manualPagination: {
      type: Boolean,
      default: true,
    },
    pdfFormat: {
      type: String,
      default: 'a4',
    },
    showLayout: {
      type: Boolean,
      default: false,
    },
    floatLayout: {
      type: Boolean,
      default: true,
    },
    enableDownload: {
      type: Boolean,
      default: true,
    },
    previewModal: {
      type: Boolean,
      default: true,
    },
    fileName: {
      type: String,
      default: 'Report',
    },
    orientation: {
      type: String,
      default: 'portrait',
    },
  },
  components: { Vue3Html2pdf },

  methods: {
    generatePDf() {
      (this.$refs.html2Pdf as any).generatePdf();
    },
    hasStartedGeneration() {
      this.$emit('generatingReport');
    },
    hasGenerated() {
      this.$emit('generatedReport');
    },
    async handleBeforeDownload({ html2pdf, options, pdfContent }) {
      if (this.pdfFile) return this.pdfFile;

      await html2pdf()
        .set(options)
        .from(pdfContent)
        .outputPdf()
        .then(async (pdf) => {
          this.$emit('beforeDownload', btoa(await pdf));
        });
    },
    async handleHasDownloaded({ html2pdf, options, pdfContent }) {
      return true;
    },
  },
});

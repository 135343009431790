import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce73e8bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-container" }
const _hoisted_2 = { class: "report-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Title = _resolveComponent("Title")!
  const _component_TenantHeader = _resolveComponent("TenantHeader")!
  const _component_ReportContent = _resolveComponent("ReportContent")!
  const _component_NoReview = _resolveComponent("NoReview")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_Title, { title: "Tenant Reference Report" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TenantHeader, {
        "average-rating": _ctx.averageRating,
        info: _ctx.info,
        "tenant-info": _ctx.tenantInfo
      }, null, 8, ["average-rating", "info", "tenant-info"]),
      (!_ctx.isEmpty)
        ? (_openBlock(), _createBlock(_component_ReportContent, {
            key: 0,
            type: _ctx.type,
            map: _ctx.map,
            info: _ctx.info
          }, null, 8, ["type", "map", "info"]))
        : (_openBlock(), _createBlock(_component_NoReview, { key: 1 }))
    ]),
    _createVNode(_component_Footer)
  ]))
}